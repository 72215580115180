.Lobby {
    .table {
        width: 300px;
        height: 300px;
        background: #654321;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
    }

    p, img {
        position: absolute;
        transform: translate(-50%, -50%);
    }

    img {
        width: 120px;
        transform: translate(-50%, -50%) rotate(3.14rad);
    }

    button {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: calc(50% + 200px);
    }
}