.Game {
  .table-contents {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    transform: translateX(-50%);
    top: calc(47% - 150px);
    left: 50%;

    .decks-container {
      display: flex;
      margin-bottom: 24px;
      justify-content: center;

      .Deck {
        width: 120px;
        height: 187px;
        margin: 0 24px;
      }

      & > .Card {
        width: 160px;
        height: 240px;
        margin: 0 8px;
      }
    }

    .instructions {
      line-height: 1.4em;
      text-align: center;
    }

    .turn-button {
      margin: 20px auto 0;
      width: fit-content;
    }
  }
}