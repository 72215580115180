.Deck {
  position: relative;

  &.selectable {
    cursor: pointer;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}