.FlyingCard {
  background: url(/assets/back.png);
  background-size: cover;
  width: 120px;
  height: 187px;
  position: absolute;
  transition: 0.75s ease all;
  z-index: 4;

  &.flying {
    width: 6vmax;
    height: 9vmax;
    transform: translate(-50%, -50%);
  }
}