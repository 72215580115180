.CreateProfile {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
        margin-top: 16px;
    }

    input {
        font-size: 1.4em;
        padding: 8px;
        margin-top: 16px;
    }
}