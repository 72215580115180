.Player {
  background: #2c3e50;
  border-radius: 8px;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 256px;
  z-index: 5;

  .hearts-container {
    display: flex;
    align-items: center;

    p {
      flex-grow: 1;
      font-size: 0.9em;
    }

    img {
      margin: 0 0 0 4px;
      height: 24px;
    }
  }

  .top-row {
    display: flex;
    margin: 8px 0 12px;
    height: 72px;

    .character {
      border: 2px solid #fff;
      border-radius: 4px;
      width: 72px;
    }

    .Hand {
      padding-bottom: 8px;
    }
  }

  .bottom-row {
    height: 96px;

    img {
      height: 100%;
      margin-right: 8px;
      width: auto;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .select-button {
    margin-top: 8px;
  }

  .gun {
    width: 72px;
  }
}