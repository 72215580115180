.Hand {
  &.main {
    position: absolute;
    bottom: 0;

    img {
      width: 10vmax;

      &:hover, &.selected {
        margin-bottom: 0;
        width: 12vmax;
      }
    }
  }

  align-items: flex-end;
  display: flex;
  justify-content: center;
  width: 100%;

  img {
    cursor: pointer;
    margin: 0 -1%;
    transform-origin: top right;
    transition: 0.25s ease all;
    width: 25%;

    &:hover, &.selected {
      margin-bottom: 3%;
      width: 30%;
      z-index: 5;
    }
  }
}